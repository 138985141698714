<template>
  <div class="container">
    <van-nav-bar
      title="变更手机"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <div class="kbox-gray5"></div>
    <van-cell-group :border="false">
      <van-field
        center
        clearable
        label="手机号码"
        type="number"
        v-model="phone"
        placeholder="请输入有效手机号码"
        use-button-slot
      >
        <van-button
          slot="button"
          color="#F3C301"
          size="small"
          type="primary"
          round
          @click="sendSMS"
          >获取验证码</van-button
        >
      </van-field>
      <van-field
        center
        clearable
        label="验证码"
        v-model="code"
        placeholder="请输入验证码"
        use-button-slot
      >
      </van-field>
      <van-cell center :border="false" title-width="400rpx">
        <div slot="title">
          <p class="cellTitle">
            保存此号码供以后授权使用
          </p>
        </div>
        <van-switch v-model="selected" active-color="#F3C301" size="24px" />
      </van-cell>
    </van-cell-group>
    <div class="action-button">
      <van-button
        @click="changePhone"
        color="#F3C301"
        block
        type="primary"
        round
        >完 成</van-button
      >
    </div>
  </div>
</template>

<style scoped>
.container {
  background-color: #ffffff;
}

.cellTitle {
  color: #b3b3b3;
  font-size: 12px;
}
.action-button {
  margin: 100px 20px;
}
</style>

<script>
import UserService from "@/api/user";
export default {
  data() {
    return {
      selected: false,
      phone: "",
      code: "",
    };
  },
  methods: {
    async changePhone() {
      var params = {
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        phone: this.phone,
        code: this.code,
        type: 2,
      };
      console.log(params);
      const resp = await UserService.loginAsPhone(this, params);
      if (resp.data.code == 10000) {
        //
        this.$router.go(-1);
      }
    },
    async sendSMS() {
      var params = {
        type: 2,
        phone: this.phone,
      };
      const resp = await UserService.sendCode(this, params);
      console.log(resp);
    },
    goHome: function() {
      this.$router.push("/");
    },
    goBack: function() {
      this.$router.go(-1);
    },
    submit: function() {
      this.$router.push("/person");
    },
  },
};
</script>
